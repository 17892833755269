import { Box, Popper, PopperProps as MuiPopperProps, useTheme } from "@mui/material";
import GraphPopperLegendItem from "./GraphPopperLegendItem";

interface GraphPopperProps {
  PopperProps: MuiPopperProps;
  legend: Array<{
    data: Array<number | null>,
    color: any,
    valueFormatter: (v: number | null) => any
  }>
}
const GraphPopper = (props: GraphPopperProps) => {
  const { legend, PopperProps } = props;
  const theme = useTheme();

  // locals
  const pc = props.PopperProps.children as any;
  const index = Number(pc.props?.axisData?.x?.index);

  if (isNaN(index)) return null;

  return (
    <Popper
      sx={{
        pointerEvents: "none",
        pl: 2,
      }}
      {...PopperProps}
    >
      <Box
        sx={{
          background: `rgba(0, 0, 0, 0.5)`,
          borderRadius: 2,
          py: 1.75,
          px: 2,
          color: `${theme.palette.common.white}`
        }}
      >
        {legend.map((l, i) =>
        <>
          {console.log(l.data)}
          <GraphPopperLegendItem
            key={i}
            value={l.valueFormatter(l.data[index])}
            color={l.color}
          />
          </>
        )}
      </Box>
    </Popper>
  )
};

export default GraphPopper;