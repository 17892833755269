import { CoreUtils } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { BarChartPro, axisClasses, chartsGridClasses } from "@mui/x-charts-pro";
import { CommonComponents } from "common";
import useZoom from "../useZoom";
import FinancialsSummarySpendTrendData from "./FinancialsSummarySpendTrendData";

interface FinancialsSummarySpendTrendGraphProps {
  data: FinancialsSummarySpendTrendData;
  show: number;
  size: number;
  position: number;
}
const FinancialsSummarySpendTrendGraph = (props: FinancialsSummarySpendTrendGraphProps) => {
  const { data, show, size, position } = props;
  const theme = useTheme();

  // locals
  const axisId = "x-axis";
  const count = data.label.length
  const { zoom, setZoom } = useZoom(axisId, size, count, show, position);
  const series = {
    committed: {
      data: data.committed,
      color: '#FFB882',
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "",
    },
    allocated: {
      data: data?.allocated,
      color: '#CB5800',
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "",
    },
    paid: {
      data: data?.paid,
      color: '#FF6F00',
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "",
    },
    projected: {
      data: data?.projected,
      color: '#E3E6E8',
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "",
    },
  }

  return (
    <BarChartPro
      sx={{
        [`.${axisClasses.root} .${axisClasses.line}, & .${chartsGridClasses.line}`]: {
          stroke: `${theme.palette.grey[200]}`,
          strokeWidth: 1,
        },
      }}
      grid={{
        horizontal: true,
        vertical: false,
      }}
      margin={{
        top: 0,
        left: 8,
        right: 8,
        bottom: 24,
      }}
      leftAxis={null}
      height={300}
      zoom={zoom}
      borderRadius={6}
      onZoomChange={setZoom}
      xAxis={[
        {
          id: axisId,
          zoom: {
            step: 0,
            panning: true,
          },
          scaleType: 'band',
          dataKey: 'month',
          data: data?.label,
          categoryGapRatio: 0.6,
          barGapRatio: 0.1,
          disableTicks: true
        } as any
      ]}
      slots={{
        popper: (props) => (
          <CommonComponents.GraphPopper
            PopperProps={props}
            legend={[
              series.committed,
              series.allocated,
              series.paid,
              series.projected
            ]}
          />
        )
      }}
      series={[
        { ...series.committed, type: "bar", stack: "total" },
        { ...series.allocated, type: "bar", stack: "total" },
        { ...series.paid, type: "bar", stack: "total" },
        { ...series.projected, type: "bar", stack: "total" },
      ]}
    />
  )
}
export default FinancialsSummarySpendTrendGraph;