import { Stack, Box, Typography, useTheme } from "@mui/material";

interface GraphPopperLegendItemProps {
  color: string;
  value: string;
}
const GraphPopperLegendItem = (props: GraphPopperLegendItemProps) => {
  const { color, value } = props;  
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <Box 
        sx={{ 
          width: 14, 
          height: 14, 
          mr: 1, 
          borderRadius: 100, 
          background: color,
          border: `solid 1px ${theme.palette.grey[300]}` 
        }} 
      />
      <Typography variant="body1" sx={{ fontWeight: 500 }}> {value} </Typography>
    </Stack>
  )
}
export default GraphPopperLegendItem;