import { CoreComponents, CoreState } from "@build-buddy/core";
import { Close as CloseIcon, Edit as EditIcon, InfoOutlined as InfoIcon, Payment as PaymentIcon } from "@mui/icons-material";
import { Box, Collapse, FormControlLabel, IconButton, Radio, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CreditCard } from "common/components";

interface PaymentOptionsCreditCardProps {
  show: boolean;
  number: string;
  name: string;
  expiry: string;
  isLoading?: boolean;
}
const PaymentOptionsCreditCard = (props: PaymentOptionsCreditCardProps) => {
  const { show, number, name, expiry, isLoading } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(!number);

  const handleEdit = () => {
    setIsEdit(!isEdit);
  }

  return (
    <>
      <FormControlLabel
        sx={{
          mr: 0.5,
          "& > .MuiTypography-root": {
            flex: 1
          }
        }}
        value={CoreState.Financial.PaymentType.CreditCard}
        control={<Radio />}
        label={
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <PaymentIcon sx={{ fontSize: "24px", color: theme.palette.grey[500] }} />
            <Typography variant="body1" sx={{ fontWeight: "bold", flex: 1 }}> Credit Card </Typography>
            <IconButton
              edge="end"
              onClick={() => handleEdit()}
            >
              {!isEdit &&
                <EditIcon sx={{ fontSize: "18px" }} />
              }
              {isEdit &&
                <CloseIcon sx={{ fontSize: "20px" }} />
              }
            </IconButton>
          </Stack>
        }
      />
      <Collapse in={show}>
        <Box sx={{ mt: 1.5, mb: 2, ml: 3.5 }}>
          {isEdit &&
            <CoreComponents.HostedCreditCard
              onSuccess={() => {
                const message = `Credit card details saved successfully.`;
                dispatch(CoreState.Notification.SnackBarActions.addSuccessSnackBar({ message }));
                setIsEdit(false)
              }}
              onError={(response) => {
                dispatch(CoreState.Notification.SnackBarActions.addErrorSnackBar({ template: CoreState.Common.SnackbarTemplate.Support }));
                console.log(response)
              }}
            />
          }
          {!isEdit &&
            <CreditCard
              number={number}
              name={name}
              expiry={expiry}
              sx={{ mt: 1 }}
              isLoading={Boolean(isLoading)}
            />
          }
        </Box>
        <Stack direction="row" alignItems="center" sx={{ ml: 3 }}>
          <InfoIcon sx={{ mr: 1, fontSize: "20px", position: "relative", bottom: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: "bold" }}> No surcharge applies </Typography>
        </Stack>
      </Collapse>
    </>
  )
}
export default PaymentOptionsCreditCard;