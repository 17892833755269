import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { ProjectTaskStatus } from "modules/project/_models";
import { selectProjectTask, selectProjectTaskStatus } from "modules/project/_selectors";
import { useEffect, useState } from "react";
import { useTaskViewParams } from "../../_hooks";
import { TaskViewDiy } from "../_common";

const TaskViewRequestActions = () => {
  const { projectId, stageId, taskId } = useTaskViewParams();

  // params
  const taskParams = { projectId, stageId, taskId };

  // selectors
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams));
  const projectTaskStatus = useSelector((s) => selectProjectTaskStatus(s, taskParams));

  // mutations
  const [beginTaskAction, beginTaskQuery] = CoreState.Task.beginTask.useMutation();
  const [completeTaskAction, completeTaskQuery] = CoreState.Task.completeTask.useMutation();

  // locals
  const [open, setOpen] = useState(false);

  const showActions = Boolean(projectTaskStatus !== ProjectTaskStatus.Completed);
  const isSaving = Boolean(beginTaskQuery.isLoading || completeTaskQuery.isLoading);

  const handleConfirm = () => {
    if (!projectTask) return;

    beginTaskAction({
      projectId: projectId,
      taskId: projectTask.task.parentId,
      startDate: projectTask.task.startDate,
      endDate: projectTask.task.endDate
    })

    completeTaskAction({
      projectId: projectId,
      parentId: projectTask?.task.parentId,
      taskId: projectTask?.task.id
    })
  }

  useEffect(() => {
    if (!beginTaskQuery.isSuccess || !completeTaskQuery.isSuccess) return;
    setOpen(false);
  }, [beginTaskQuery.isSuccess, completeTaskQuery.isSuccess]);

  if (!showActions) return null;

  return (
    <>
      <CoreComponents.Confirmation
        open={open}
        title="Have you reviewed your Scope of Works?"
        text="Your Scope of Works for each task is important to ensure accurate quoting. Please make sure that you review the Scope of Works and make necessary adjustments before deciding to request quotes."
        cancelText="No, review my Scope of Works"
        confirmText="Yes, I am ready to request quotes"
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
        loading={isSaving}
      />
      <Button
        fullWidth
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Request Quotes
      </Button>
      {/* DIY */}
      <TaskViewDiy />
    </>
  )
}
export default TaskViewRequestActions