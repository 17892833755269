import { CoreComponents, CoreState, CoreUtils, useSelector } from "@build-buddy/core"
import { Card, CardContent, Typography } from "@mui/material"
import { compareAsc } from "date-fns"
import { useMemo } from "react"
import { useFinancialRoutesParams } from "../../FinancialsRoutes"
import FinancialsSummarySpendTotalGraph from "./FinancialsSummarySpendTotalGraph"
import FinancialsSummarySpendTotalSkeleton from "./FinancialsSummarySpendTotalSkeleton"

const FinancialsSummarySpendTotal = () => {
  const { projectId } = useFinancialRoutesParams();
  const projectFinancialsByProjectParams = { projectId };
  const spendingTotalsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getSpendingTotalsByProject.useQuery(projectFinancialsByProjectParams);

  // locals
  const spendingTotals = useSelector(s => CoreState.Financial.selectSpendingTotalsByProject(s, spendingTotalsByProjectParams));
  const spendTotalsData = useMemo(() => {
    if (!spendingTotals) return;

    const estimated: Array<number | null> = [];
    const spend: Array<number | null> = [];
    const forecast: Array<number | null> = [];
    const labels: Array<string> = [];

    let today = new Date();
    let applied = false;

    spendingTotals?.totals.forEach(x => {
      const date = new Date(`${x.month}/${x.year}`);
      const isPastOrCurrent = Boolean(compareAsc(today, date) === 1)

      // if its the future (not past or current) we set the spend value null
      let spent: number | null = !isPastOrCurrent ? null : x.spend;

      // if its in the past or current, we set the forecasted value null
      let forecasted: number | null = isPastOrCurrent ? null : x.forecasted;

      // ok theres a bit of trickery here, to make the graph display correctly we need to set the spend
      // value to the forecasted value ONLY for the NEXT item after the current month. After we set it, we 
      // set applied to true so it never happens again
      if (!isPastOrCurrent && !applied) {
        spent = forecasted;
        applied = true;
      }

      estimated.push(x.estimated);
      spend.push(spent);
      forecast.push(forecasted);
      labels.push(CoreUtils.Formatter.date(date, "MMM yy") || "");
    })

    return { estimated, spend, forecast, labels };
  }, [spendingTotals]);

  const isEmpty = Boolean(!spendingTotals);
  const isLoading = Boolean(projectFinancialsByProjectQuery.isUninitialized || projectFinancialsByProjectQuery.isLoading);

  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<FinancialsSummarySpendTotalSkeleton />} 
      isEmpty={isEmpty}
      data={spendTotalsData}
      render={d => (
        <Card>
          <CardContent sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              sx={{ flex: 1, fontWeight: "bold", height: 48, position: "relative", top: 16 }}
            >
              Cumulative Spend
            </Typography>
            <FinancialsSummarySpendTotalGraph data={d} />
          </CardContent>
        </Card>
      )}
    />
  )
}

export default FinancialsSummarySpendTotal;