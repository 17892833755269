import { CoreHooks } from "@build-buddy/core";
import { Card, CardContent, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import FinancialsSummarySpendTrendGraph from "./FinancialsSummarySpendTrendGraph";
import FinancialsSummarySpendTrendData from "./FinancialsSummarySpendTrendData";

interface FinancialsSummarySpendTrendCardProps {
  data: {
    quarterly: FinancialsSummarySpendTrendData;
    monthly: FinancialsSummarySpendTrendData;
    fortnightly: FinancialsSummarySpendTrendData;
  },
  position: {
    quarterly: number;
    monthly: number;
    fortnightly: number;
  }
}
const FinancialsSummarySpendTrendCard = (props: FinancialsSummarySpendTrendCardProps) => {
  const { data, position } = props;

  const kvp = [
    { key: "quarterly", show: 4, position: position.quarterly },
    { key: "monthly", show: 6, position: position.monthly },
    { key: "fortnightly", show: 6, position: position.fortnightly }
  ];

  const [val, setVal] = useState(1);
  const [cardWidth, setCardWidth] = useState(0);
  const settings = kvp[val];
  const selectedData = data[settings.key as keyof typeof data];
  const windowSize = CoreHooks.useWindowSize();
  const tabs = Boolean(cardWidth > 450) ? ["Quaterly", "Monthly", "Fortnightly"] : ["QTR", "MTH", "FN"];

  const measuredRef = useCallback((el: any) => {
    if (!el) return;
    setCardWidth(el.getBoundingClientRect().width);
  }, [windowSize.width])

  return (
    <Card ref={measuredRef}>
      <CardContent sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body2"
            sx={{ flex: 1, fontWeight: "bold" }}
          >
            Spending Trend
          </Typography>
          <Tabs value={val} onChange={(e, val) => setVal(val)}>
            {tabs.map((t, i) => <Tab key={i} sx={{ minWidth: 0 }} label={t} tabIndex={i} />)}
          </Tabs>
        </Stack>
        <FinancialsSummarySpendTrendGraph
          data={selectedData}
          size={cardWidth}
          show={settings.show}
          position={settings.position}
        />
      </CardContent>
    </Card>
  )
}
export default FinancialsSummarySpendTrendCard;