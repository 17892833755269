import { CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { CommonComponents } from "common";
import { useSelector } from "common/state/store";
import { useFinancialRoutesParams } from "../FinancialsRoutes";
import FinancialsSummaryBudget from "./financials-summary-budget/FinancialsSummaryBudget";
import FinancialsSummaryCompleted from "./financials-summary-completed/FinancialsSummaryCompleted";
import FinancialsSummaryLegend from "./financials-summary-legend/FinancialsSummaryLegend";
import FinancialsSummarySpendTrend from "./financials-summary-spend-trend/FinancialsSummarySpendTrend";
import FinancialsSummarySpendTotal from "./financials-summary-spend-total/FinancialsSummarySpendTotal";

const FinancialsSummary = () => {
  const { projectId } = useFinancialRoutesParams();
  const projectFinancialsByProjectParams = { projectId };
  
  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);
  
  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: `40% minmax(250px, 20%) 1fr 1fr minmax(250px, 20%)`,
        gridTemplateRows: `64px 75px 190px auto`,
        gridTemplateAreas: `
          'budget legend legend legend legend'
          'budget piechart piechart piechart completed'
          'budget piechart piechart piechart savings'
          'budget graph1 graph1 graph2 graph2'
        `
      }}
    >
      <Box
        sx={{
          gridArea: "budget"
        }}
      >
        <FinancialsSummaryBudget />
      </Box>
      <Box
        sx={{
          gridArea: "legend",
        }}
      >
        <FinancialsSummaryLegend />
      </Box>
      <Box
        sx={{
          gridArea: "piechart",
        }}
      >
        <CommonComponents.CostsPieCard
          sx={{ height: "100%" }}
          isLoading={isLoading}
          data={{
            paid: projectFinancials?.paid || 0,
            allocated: projectFinancials?.allocated || 0,
            balance: projectFinancials?.balance || 0,
            due: projectFinancials?.due || 0,
            committed: projectFinancials?.committed || 0,
            forecast: projectFinancials?.forecast || 0,
          }}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          CostsPieLegendProps={{ hideLabels: true }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "completed",
          position: 'relative'
        }}
      >
        <FinancialsSummaryCompleted />
      </Box>
      <Box
        sx={{
          gridArea: "savings"
        }}
      >
        <CommonComponents.SavingsCard
          isLoading={isLoading}
          savings={projectFinancials?.savings || 0}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          sx={{ height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "graph1",
        }}
      >
        <FinancialsSummarySpendTrend />
      </Box>
      <Box
        sx={{
          gridArea: "graph2",
        }}
      >
        <FinancialsSummarySpendTotal />
      </Box>
    </Box>
  )
}
export default FinancialsSummary;