import { Card, CardContent, useTheme } from '@mui/material';
import { LineChartPro, useDrawingArea, ZoomData } from '@mui/x-charts-pro';
import { toolTipValueFormatter } from 'common/components/display/graph/utils/utils';
import { useEffect, useState } from 'react';

const Colorswitch = () => {
  const theme = useTheme();
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  return (
    <>
      <defs>
        <linearGradient id="blue_gradient" x1="300" y1="-4" x2="300" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop stop-color={theme.palette.blue[600]} stop-opacity="0.4" />
          <stop offset="1" stop-color={theme.palette.blue[600]} stop-opacity="0" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="grey_gradient" x1="300" y1="-4" x2="300" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop stop-color={theme.palette.grey[600]} stop-opacity="0.4" />
          <stop offset="1" stop-color={theme.palette.grey[600]} stop-opacity="0" />
        </linearGradient>
      </defs>
    </>
  )
}

const data = {
  "estimated": [
    // 66457.65392958355,
    // 78368.46756594718,
    // 86929.28120231081,
    // 132152.40512687404,
    // 178068.34657354426,
    // 279188.9102099079,
    // 377592.90957729024,
    // 394253.7260623933,
    // 426502.176007527,
    // 475849.28964389063,
    // 620500.0004200519
    70,
    150,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000
  ],
  "spend": [
    50,
    100,
    360,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  "forecast": [
    null,
    null,
    360,
    480,
    600,
    720,
    840,
    960,
    1080,
    1200
  ],
  "labels": [
    "Sep-24",
    "Oct-24",
    "Nov-24",
    "Jan-25",
    "Feb-25",
    "Mar-25",
    "Apr-25",
    "May-25",
    "Jun-25",
    "Jul-25",
  ]
}

const useZoom = (axisId: string, size: number, length: number, show: number, position: number) => {
  const [zoom, setZoom] = useState<ZoomData[]>([{
    axisId: axisId,
    start: 0,
    end: 60,
  }]);

  useEffect(() => {    
    // if the size of the container is >= 400, we show the defined amount. Otherwise we ALWAYS show 4 items
    const noToShow = Boolean(size >= 400) ? show : 4;

    // we calculate the start percentage of the zoom
    const zoomAmount = Math.round((noToShow / length) * 100);
    const zoomPosition = Math.round((position / length) * 100);

    // calculate the end position
    const calcEnd = zoomPosition + zoomAmount;
    // if the calculated end is > 100, then we minus 100 from the zoomAmount. Otherwise just use the zoomPosition
    const start = (calcEnd > 100) ? 100 - zoomAmount : zoomPosition;
    // use calculated end and cap at 100
    const end = Math.min(calcEnd, 100);
    
    setZoom([{ axisId, start, end }]);
  }, [size, length, show])

  return { zoom, setZoom };
}

const Sandbox = () => {
  const theme = useTheme();
  const axisId = "x-axis";
  const size = 400;
  const count = data.labels.length;
  const show = 7;
  const position = 4;

  const { zoom, setZoom } = useZoom(axisId, size, count, show, position);

  return (
    <Card sx={{ m: 8, width: 600 }}>
      <CardContent>
        <LineChartPro
          height={300}
          margin={{
            right: 24,
            left: 24,
            bottom: 24
          }}  
          zoom={zoom}
          onZoomChange={setZoom}        
          xAxis={[{
            id: "x-axis",
            zoom: {
              step: 0,
              panning: true,
            },
            scaleType: 'point',
            data: data.labels,
            disableLine: true,
            disableTicks: true,
          }] as any}
          series={[
            {
              data: data.spend,
              type: 'line',
              color: theme.palette.grey[500],
              id: 'spend',
              valueFormatter: (v) => toolTipValueFormatter(v),
              area: true
            },
            {
              data: data.forecast,
              type: 'line',
              color: theme.palette.grey[500],
              id: 'forecast',
              valueFormatter: (v) => toolTipValueFormatter(v),
            },
            {
              data: data.estimated,
              type: 'line',
              color: theme.palette.blue[600],
              id: 'estimated',
              valueFormatter: (v) => toolTipValueFormatter(v),
              area: true
            },
          ]}
          slots={{
          }}
          slotProps={{
          }}
          leftAxis={null}
          sx={{
            '.MuiAreaElement-series-estimated': {
              fill: 'url(#blue_gradient)',
            },
            '.MuiAreaElement-series-spend': {
              fill: 'url(#grey_gradient)',
            },
            ".MuiLineElement-series-forecast": {
              strokeDasharray: '8 3',
            }
          }}
        >
          <Colorswitch />
        </LineChartPro>
      </CardContent>
    </Card>
  )
}

export default Sandbox