import { CoreComponents, CoreState, CoreUtils, useSelector } from "@build-buddy/core";
import { differenceInDays, startOfYear } from "date-fns";
import { useMemo } from "react";
import { useFinancialRoutesParams } from "../../FinancialsRoutes";
import FinancialsSummarySpendTrendCard from "./FinancialsSummarySpendTrendCard";
import FinancialsSummarySpendTrendData from "./FinancialsSummarySpendTrendData";
import FinancialsSummarySpendTrendSkeleton from "./FinancialsSummarySpendTrendSkeleton";

const FinancialsSummarySpendTrend = () => {
  // params
  const { projectId } = useFinancialRoutesParams();
  const spendingTrendByProjectParams = { projectId };

  // queries
  const spendingTrendByProjectQuery = CoreState.Financial.getSpendingTrendByProject.useQuery(spendingTrendByProjectParams);

  // locals
  const spendingTrend = useSelector(s => CoreState.Financial.selectSpendingTrendByProject(s, spendingTrendByProjectParams));
  const spendTrendData = useMemo(() => {
    if (!spendingTrend) return;

    const map = (
      data: Array<CoreState.Financial.SpendingTrendItem>,
      // function to generate the label
      labelFn: (item: CoreState.Financial.SpendingTrendItem, index: number) => string,
      // function to determine the index for the current position
      posFn: (item: CoreState.Financial.SpendingTrendItem) => boolean
    ): { position: number, data: FinancialsSummarySpendTrendData } => {
      const committed: number[] = [];
      const allocated: number[] = [];
      const paid: number[] = [];
      const projected: number[] = [];
      const label: string[] = [];
      let position: number = -1;

      data.forEach((spendTrendItem, i: number) => {
        committed.push(spendTrendItem.committed);
        allocated.push(spendTrendItem.allocated);
        paid.push(spendTrendItem.paid);
        projected.push(spendTrendItem.projected);
        label.push(labelFn(spendTrendItem, i));
        if (position < 0 && posFn(spendTrendItem)) position = i;
      })

      return {
        position,
        data: { committed, allocated, paid, projected, label }
      };
    }

    const MONTHLY_FORMAT = "MMM yy";
    const currentDate = new Date();
    const currentFortnight = (() => {
      const currentDate = new Date();
      const startOfThisYear = startOfYear(currentDate);
      const dayOfYear = differenceInDays(currentDate, startOfThisYear) + 1;
      return Math.ceil(dayOfYear / 14);
    })();

    const fortnightly = map(
      spendingTrend.fortnightly,
      item => item.fortnight.toString(),
      item => Boolean(item.fortnight === currentFortnight)
    );

    const monthly = map(
      spendingTrend.monthly,
      item => CoreUtils.Formatter.date(new Date(`${item.month}/${item.year}`), MONTHLY_FORMAT) || "",
      item => {
        const cmp1 = CoreUtils.Formatter.date(currentDate, MONTHLY_FORMAT);
        const cmp2 = CoreUtils.Formatter.date(new Date(`${item.month}/${item.year}`), MONTHLY_FORMAT);
        return (cmp1 === cmp2)
      }
    );

    const quarterly = map(
      spendingTrend.quarterly,
      item => `Q${item.quarter} ${CoreUtils.Formatter.date(new Date(item.year, 1, 1), "yy")}`,
      item => {
        const cmp1 = `${item.quarter} ${CoreUtils.Formatter.date(new Date(item.year, 1, 1), "yyyy")}`;
        const cmp2 = `${Math.floor(((new Date(currentDate).getMonth()) % 12) / 3) + 1} ${new Date(currentDate).getFullYear()}`;
        return (cmp1 === cmp2)
      }
    );

    return {
      data: { fortnightly: fortnightly.data, monthly: monthly.data, quarterly: quarterly.data },
      position: { fortnightly: fortnightly.position, monthly: monthly.position, quarterly: quarterly.position }
    }
  }, [spendingTrend]);
  const isEmpty = Boolean(!spendingTrend);
  const isLoading = Boolean(spendingTrendByProjectQuery.isUninitialized || spendingTrendByProjectQuery.isLoading);

  return (
    <CoreComponents.PreContent
      isLoading={isLoading}
      loader={<FinancialsSummarySpendTrendSkeleton />}
      isEmpty={isEmpty}
      data={spendTrendData}
      render={d => (
        <FinancialsSummarySpendTrendCard
          data={d.data}
          position={d.position}
        />
      )}
    />
  )
}
export default FinancialsSummarySpendTrend;