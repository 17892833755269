import { CoreUtils } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { LineChartPro } from "@mui/x-charts-pro";
import { CommonComponents } from "common";
import useZoom from "../useZoom";
import FinancialsSummarySpendTotalGradient from "./FinancialsSummarySpendTotalGradient";

interface FinancialsSummarySpendTotalGraphProps {
  data: {
    labels: Array<string>;
    estimated: Array<number | null>;
    forecast: Array<number | null>;
    spend: Array<number | null>;
  }
}
const FinancialsSummarySpendTotalGraph = (props: FinancialsSummarySpendTotalGraphProps) => {
  const { data } = props;
  const theme = useTheme();

  // locals
  const axisId = "x-axis";
  const size = 400;
  const count = data.labels.length;
  const show = 7;
  const position = 1;
  const { zoom, setZoom } = useZoom(axisId, size, count, show, position);
  const series = {
    spend: {
      data: data.spend,
      color: theme.palette.grey[500],
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "1",
    },
    forecast: {
      data: data.forecast,
      color: theme.palette.grey[500],
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "1",
    },
    estimated: {
      data: data.estimated,
      color: theme.palette.blue[600],
      valueFormatter: (v: number | null) => CoreUtils.Formatter.currency(v) || "1",
    },
  }

  return (
    <LineChartPro
      height={300}
      margin={{
        right: 28,
        left: 28,
        bottom: 24
      }}
      zoom={zoom}
      onZoomChange={setZoom}
      xAxis={[{
        id: "x-axis",
        zoom: {
          step: 0,
          panning: true,
        },
        scaleType: 'point',
        data: data.labels,
        disableLine: true,
        disableTicks: true,
      }] as any}
      series={[
        { ...series.spend, type: "line", id: "spend", area: true },
        { ...series.forecast, type: "line", id: "forecast" },
        { ...series.estimated, type: "line", id: "estimated", area: true },
      ]}
      slots={{
        popper: (props) => (
          <CommonComponents.GraphPopper
            PopperProps={props}
            legend={[
              series.estimated,
              // little bit of magic here, we only want to show 2 legend items, 
              // since spend & forecast is the same line. So merge both arrays and replace null values
              { 
                ...series.spend,
                data: series.spend.data.map((value, index) => value === null ? series.forecast.data[index] : value)
              }
            ]}
          />
        )
      }}
      leftAxis={null}
      sx={{
        '.MuiChartsAxisHighlight-root': {
          strokeWidth: 0.5,
        },
        '.MuiAreaElement-series-estimated': {
          fill: 'url(#blue_gradient)',
        },
        '.MuiAreaElement-series-spend': {
          fill: 'url(#grey_gradient)',
        },
        ".MuiLineElement-series-forecast": {
          strokeDasharray: '8 3',
        }
      }}
    >
      <FinancialsSummarySpendTotalGradient />
    </LineChartPro>
  )
}

export default FinancialsSummarySpendTotalGraph