import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core"
import { Box, Typography, Card, CardContent, Stack, Button } from "@mui/material";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";

export interface PaymentReceiptPaidProps {
  task: CoreState.Task.Task;
  paymentSchedule: CoreState.Financial.PaymentSchedule;
  onDownloadClick(): void;
}
const PaymentReceiptPaid = (props: PaymentReceiptPaidProps) => {
  const { task, paymentSchedule, onDownloadClick } = props;

  const taskName = task?.name || "";
  const taskDescription = task?.description || "";
  const paymentScheduleTxId = `ps-${CoreUtils.Converter.toShortUUID(paymentSchedule?.id || "")}`;
  const paymentScheduleDesc = paymentSchedule?.description;
  const showDownloadReceiptButton = paymentSchedule.status === CoreState.Financial.PaymentScheduleStatus.Paid;

  return (
    <Box
      sx={{
        maxWidth: "600px",
        mx: "auto"
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <CheckCircleOutlineIcon sx={{ fontSize: 96, mb: 1 }} color="primary" />
        <Typography variant="h1" sx={{ mb: 1 }}> Thank You for Your Payment </Typography>
        <Typography variant="body1"> You have paid for the following </Typography>
      </Box>
      <Card sx={{ my: 3 }}>
        <CardContent>
          <CoreComponents.TaskLabel
            label={taskName}
            isActive={task.isCurrent}
            isDIY={task.parentIsDIY}
          />
          <Typography variant="body2" sx={{ mb: 0.25, fontWeight: "bold" }}> Reference Id </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}> {paymentScheduleTxId} </Typography>
          <Typography variant="body1" sx={{ mb: 0.5, fontWeight: "bold" }}> {paymentScheduleDesc} </Typography>
          <Typography variant="body1"> {taskDescription} </Typography>
        </CardContent>
      </Card>
      {showDownloadReceiptButton &&
        <Stack spacing={1} sx={{ maxWidth: "80%", mx: "auto" }}>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => onDownloadClick()}
          >
            Download Receipt
          </Button>
        </Stack>
      }
    </Box>
  )
}
export default PaymentReceiptPaid