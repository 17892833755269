import React from "react";
import { CoreState } from "@build-buddy/core";
import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { getConfig } from "common";
import { useDispatch, useSelector } from "common/state/store";

import "./card.scss";

const fieldStyles = {
  input: {
    color: "#000645",
    "letter-spacing": "0.02em"
  },
  ".invalid": {
    color: "#cc0001"
  },
  "::placeholder": {
    color: "#bbb",
    fontSize: '13px',
  },
  ".invalid .card-icon": {
    color: "#cc0001"
  },
};

type HostedCardFormProps = {
  handleResponse: React.Dispatch<React.SetStateAction<any>>;
  isEditForm?: boolean;
}

function HostedCreditCardForm(props: HostedCardFormProps) {
  const dispatch = useDispatch()

  const [fieldErrors, setFieldErrors] = React.useState<any>({});

  // Selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);

  // Query
  const getFinancialsDetails = CoreState.Wallet.getFinancialDetails.useQuery({ userId })

  React.useEffect(() => {
    const cfg = getConfig()
    // Initialise hosted fields
    // @ts-ignore
    const hostedFields = assembly.hostedFields({ environment: cfg.ZaiEnv });
    const cardName = hostedFields.create("cardName", {
      placeholder: "Full Name",
      styles: fieldStyles
    });
    const cardNumber = hostedFields.create("cardNumber", {
      placeholder: "•••• •••• •••• ••••",
      styles: fieldStyles
    });
    const cardExpiry = hostedFields.create("cardExpiry", {
      placeholder: "MM/YY",
      styles: fieldStyles
    });
    const cardCvv = hostedFields.create("cardCvv", {
      placeholder: "•••",
      styles: fieldStyles
    });
    cardName.mount("#card-name-field");
    cardNumber.mount("#card-number-field");
    cardExpiry.mount("#card-expiry-field");
    cardCvv.mount("#card-cvv-field");


    const inputs = [cardName, cardNumber, cardExpiry, cardCvv];

    inputs.forEach(function (field) {
      field.on("change", (event: any) => {
        setFieldErrors((prevState: any) => ({
          ...prevState,
          [event.fieldType]: event.error ? event.error.message : undefined
        }));
      });
    });

    const form = document.querySelector("form");
    form?.addEventListener("submit", async (event) => {
      event.preventDefault();
      props.handleResponse({ type: '', isLoading: true })
      const details = await getFinancialsDetails.refetch()
      hostedFields
        .createCardAccount({
          token: details.data?.user?.cardToken,
          user_id: details.data?.user?.id
        })
        .then(function (response: any) {
          props.handleResponse({
            type: 'success',
            isLoading: false
          })
          dispatch(CoreState.ApiSlice.util.invalidateTags([CoreState.Wallet.WalletTags.Financial]));
        })
        .catch(function (response: any) {
          // handle errors
          props.handleResponse({ type: 'error', isLoading: false })
        });
    });

    return function cleanup() {
      hostedFields.destroy();
    };
  }, []);


  return (
    <form className="card-wrapper">
      <div className="cardName-container field-container">
        <label htmlFor="card-name-field">Cardholder Name</label>
        <div id="card-name-field" className="hosted-field"></div>
        {fieldErrors.cardName && (
          <div className="error-message">{fieldErrors.cardName}</div>
        )}
      </div>
      <div className="cardNumber-container field-container">
        <label htmlFor="card-number-field">Card Number</label>
        <div id="card-number-field" className="hosted-field"></div>
        {fieldErrors.cardNumber && (
          <div className="error-message">{fieldErrors.cardNumber}</div>
        )}
      </div>
      <div className="field-columns">
        <div className="cardExpiry-container field-container">
          <label htmlFor="card-expiry-field">Expiry Date</label>
          <div id="card-expiry-field" className="hosted-field"></div>
          {fieldErrors.cardExpiry && (
            <div className="error-message">{fieldErrors.cardExpiry}</div>
          )}
        </div>
        <div className="cardCvv-container field-container">
          <label htmlFor="card-cvv-field">CVV</label>
          <div id="card-cvv-field" className="hosted-field"></div>
          {fieldErrors.cardCvv && (
            <div className="error-message">{fieldErrors.cardCvv}</div>
          )}
        </div>
      </div>

      <Button
        type="submit"
        variant="contained"
        fullWidth
      >
        {props.isEditForm ? 'Update Card' : 'Add Card'}
      </Button>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 2 }}>
        <InfoIcon />
        <Typography>No surcharge applies.</Typography>
      </Box>
    </form>
  );
}

export default HostedCreditCardForm
