import { CoreState } from '@build-buddy/core';
import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Button, Card, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTaskViewPaymentsParams } from '../_hooks';
import { selectCurrentPaymentSchedule } from '../_selectors';
import PaymentBreakdown from './PaymentBreakdown';
import PaymentConfirmation from './PaymentConfirmation';
import { useSelector } from 'common/state/store';

type TaskViewMakePaymentProps = {
  currentMethod: CoreState.Wallet.PaymentGateway;
}

const TaskViewMakePayment = (props: TaskViewMakePaymentProps) => {
  const theme = useTheme()

  // ConfirmationType: Pay OR Allocate
  const [confirmationType, setConfirmationType] = useState('')

  const { projectId, stageId, taskId, paymentScheduleId } = useTaskViewPaymentsParams();
  const paymentScheduleParams = { projectId, stageId, taskId, paymentScheduleId };

  // Selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const cardDetails = useSelector((state) => CoreState.Wallet.selectCreditCardDetails(state, { userId }));
  const walletDetails = useSelector((state) => CoreState.Wallet.selectWalletDetails(state, { userId }));
  const bankDetails = useSelector((state) => CoreState.Wallet.selectBankDetails(state, { userId }));
  const currentPaymentSchedule = useSelector((s) => selectCurrentPaymentSchedule(s, paymentScheduleParams))

  const disablePayButton =
    (props.currentMethod === CoreState.Wallet.PaymentGateway.Card && !cardDetails?.id) ||
    (props.currentMethod === CoreState.Wallet.PaymentGateway.Wallet && (!walletDetails?.active || !bankDetails?.accountName))

  const hideAllocateButton =
    !walletDetails?.active ||
    !bankDetails?.accountName ||
    props.currentMethod === CoreState.Wallet.PaymentGateway.Card ||
    currentPaymentSchedule?.paymentType === CoreState.Quote.PaymentScheduleType.ImmediatePayment

  if (!currentPaymentSchedule) return null

  return (
    <>
      <PaymentConfirmation
        show={Boolean(confirmationType)}
        onClose={() => setConfirmationType('')}
        confirmationType={confirmationType}
        currentMethod={props.currentMethod}
      />
      <Typography variant='h6'>Summary</Typography>
      <Card sx={{ border: `1px solid ${theme.palette.grey[300]}`, px: 2, py: 1, my: 1 }}>
        <Typography
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            mx: -2,
            px: 2,
            py: .5,
            fontWeight: 600
          }}
        >
          {currentPaymentSchedule.description}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, py: 2 }}>
          <InfoIcon sx={{ fontSize: '18px' }} />
          {hideAllocateButton ?
            <Typography variant='body2'>
              Pay Now: Complete your payment instantly.
            </Typography> :
            <Typography variant='body2'>
              Allocate: Funds will be released once the task is completed.<br />
              Pay Now: Complete your payment instantly.
            </Typography>
          }
        </Box>
        <PaymentBreakdown amount={currentPaymentSchedule?.amount} currentMethod={props.currentMethod} />
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width="100%"
          gap={1}
          mt={2}
        >
          <Button
            variant='outlined'
            disabled={Boolean(disablePayButton)}
            fullWidth={true}
            onClick={() => {
              props.currentMethod === CoreState.Wallet.PaymentGateway.Card ?
                setConfirmationType(CoreState.Quote.PaymentMethod.express) :
                setConfirmationType(CoreState.Quote.PaymentMethod.payNow)
            }}
          >
            Pay Now
          </Button>
          <Button
            variant='contained'
            hidden={Boolean(hideAllocateButton)}
            fullWidth={true}
            onClick={() => setConfirmationType(CoreState.Quote.PaymentMethod.allocate)}
          >
            Allocate
          </Button>
        </Stack>
      </Card>
    </>
  )
}

export default TaskViewMakePayment
