import { CoreRouting, CoreState } from "@build-buddy/core";
import { BrushIcon, CalendarIcon, DashboardIcon, FolderIcon, GraphIcon, LayersIcon, MoneyIcon, TeamsIcon, GanttIcon } from "assets/icons";
import { CommonState, RouteDefinitions } from "common";
import { useSelector } from "common/state/store";
import { useLocation } from "react-router";

const useSideMenu = () => {
  const location = useLocation();
  const routing = CoreRouting.useRouting();

  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  // Route params
  const params = { pid: projectId };

  let menuItems = [
    {
      icon: DashboardIcon,
      label: "Dashboard",
      active: ["/dashboard"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        routing.go(RouteDefinitions.Project.DASHBOARD, { params: { pid: projectId, filter: "to-do" } });
      },
    },
    // {
    //   icon: GraphIcon,
    //   label: "Overview",
    //   active: ["/overview"].some((s) => location.pathname.includes(s)),
    //   disabled: isLimited,
    //   onClick: () => {
    //     routing.go(RouteDefinitions.Project.OVERVIEW, { params })
    //   },
    // },
    {
      icon: GanttIcon,
      label: "Gantt Chart",
      active: ["/gantt"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        routing.go(RouteDefinitions.Project.GANTT.path, { params })
      },
    },
    {
      icon: TeamsIcon,
      label: "My Teams",
      active: ["/my-teams"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        routing.go(RouteDefinitions.Project.MY_TEAMS, { params })
      },
    },
    {
      icon: CalendarIcon,
      label: "Calendar",
      active: ["/calendar"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        const params = {
          pid: projectId,
          filter:"all",
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        }
        routing.go(RouteDefinitions.Project.CALENDAR, { params })
      },
    },
    {
      icon: FolderIcon,
      label: "Document Library",
      active: ["/document"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        routing.go(RouteDefinitions.Project.DOCUMENT_LIBRARY, { params })
      },
    },
    {
      icon: MoneyIcon,
      label: "Financials",
      active: ["/financials"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        routing.go(RouteDefinitions.Project.FINANCIALS_ROOT.path, { params })
      }
    },
    {
      icon: BrushIcon,
      label: "Selections",
      active: ["/selections"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        routing.go(RouteDefinitions.Project.SELECTIONS, { params })
      },
    },
    {
      icon: LayersIcon,
      label: "Designer",
      active: ["/designer"].some((s) => location.pathname.includes(s)),
      disabled: isLimited,
      onClick: () => {
        routing.go(RouteDefinitions.Project.DESIGNER, { params })
      },
    },
  ];

  return { menuItems };
};
export default useSideMenu;
