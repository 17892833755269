import { Theme } from "@emotion/react";
import { Box, Skeleton, SxProps } from "@mui/material";
import "./CreditCard.scss";

export interface CreditCardProps {
  name: string;
  number: string;
  expiry: string;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
}
const CreditCard = (props: CreditCardProps) => {
  const { name, number, expiry, isLoading, sx } = props;

  return (
    <Box
      sx={{ ...sx }}
      className={isLoading ? "shine" : ''}
    >
      <div className="card">
        <div className="card__front card__part">
          {isLoading ?
            <Skeleton className="card_number" variant="rectangular" width="100%" sx={{ bgcolor: `rgba(255, 255, 255, 0.4)` }} /> :
            <p className="card_number">{number}</p>
          }
          <div className="card__space-75">
            <span className="card__label">Card holder</span>
            {isLoading ?
              <Skeleton className="card__info" variant="rectangular" width="75%" sx={{ bgcolor: `rgba(255, 255, 255, 0.4)` }} /> :
              <p className="card__info">{name}</p>
            }
          </div>
          <div className="card__space-25">
            <span className="card__label">Expires</span>
            {isLoading ?
              <Skeleton className="card__info" variant="rectangular" width="75%" sx={{ bgcolor: `rgba(255, 255, 255, 0.4)` }} /> :
              <p className="card__info">{expiry}</p>
            }
          </div>
        </div>
      </div>
    </Box>
  )
}
export default CreditCard;