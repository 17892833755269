import { CoreComponents } from '@build-buddy/core';
import { Box, Card, CardContent, Popper, PopperProps, Stack, styled, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { AnimatedLine, AnimatedLineProps, ChartsReferenceLine, LineChartPro, MarkElement, MarkElementProps, useChartId, useDrawingArea, useXScale } from '@mui/x-charts-pro';
import { format } from 'date-fns';
import { useEffect } from 'react';
import GraphSkeleton from './GraphSkeleton';
import { toolTipValueFormatter } from './utils/utils';

interface FinancialsLineGraphProps {
  data: {
    estimated: number[],
    forecast: number[],
    labels: string[]
  },
  isLoading: boolean;
}

const FinancialsLineGraph = (props: FinancialsLineGraphProps) => {
  const { data } = props;
  const currentDate = format(new Date(), 'MMM-yy')
  const currentMonthIndex = data?.labels.indexOf(currentDate)

  // Scroll to today.
  useEffect(() => {
    const timer = setTimeout(() => {
      const marker = document.querySelectorAll('.MuiChartsReferenceLine-label');
      if (!marker) {
        return;
      }
      marker[1]?.scrollIntoView({ behavior: 'smooth' });
    }, 500);

    return () => clearTimeout(timer);
  }, [props.data]);

  return (
    <CoreComponents.PreContent
      isLoading={props.isLoading}
      loader={<GraphSkeleton />}
      isEmpty={false}
    >
      <Card>
        <CardContent sx={{ pt: 3, px: 0, pb: '0px !important' }}>
          <Stack>
            <Typography fontSize={12} fontWeight={600} px={2}>Cumulative Spending</Typography>
            <Box
              sx={{
                height: "300px",
                aspectRatio: "1/1",
                mt: 1.5,
                overflowX: 'scroll',
                overflowY: 'hidden',
              }}
            >
              {data &&
                <LineChartPro
                  height={300}
                  width={900}
                  xAxis={[{
                    zoom: true,
                    scaleType: 'point',
                    data: data.labels,
                    disableLine: true,
                    disableTicks: true,
                  }] as any}
                  series={[
                    { data: data.forecast, type: 'line', color: '#0085FF', id: 'forecast', valueFormatter: (v) => toolTipValueFormatter(v) },
                    { data: data.estimated, type: 'line', color: '#687077', id: 'estimated', valueFormatter: (v) => toolTipValueFormatter(v) },
                  ]}
                  slots={{
                    popper: CustomPopperRoot,
                    line: CustomAnimatedLine,
                    mark: (props) => <CustomMark {...props} currentMonthIndex={currentMonthIndex} />
                  }}
                  slotProps={{
                    line: {
                      limit: currentDate,
                      sxBefore: {
                        stroke: '#687077',
                      },
                      sxAfter: { strokeDasharray: '6 5', stroke: '#E3E6E8' }
                    } as any
                  }}
                  leftAxis={null}
                  sx={{
                    '.MuiMarkElement-series-forecast': {
                      fill: '#0085FF !important',
                      stroke: '#0085FF !important',
                    },
                  }}
                >
                  <ChartsReferenceLine
                    x={currentDate}
                    label={`Scaled Label`}
                    lineStyle={{ stroke: '#eee' }}
                    labelStyle={{ fill: 'transparent' }}
                  />
                </LineChartPro>}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}

// Custom Tooltip for Graph
const ChartsTooltipRoot = styled(Popper, {
  name: "MuiChartsTooltip",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  pointerEvents: "none",
  zIndex: theme.zIndex.tooltip,
  "& .MuiChartsTooltip-paper": {
    background: 'rgba(0, 0, 0, .3)',
    padding: '4px 8px'
  },
  'thead': {
    "tr": {
      "td": {
        padding: '0px !important',
        "p": {
          color: 'white'
        }
      }
    },
  },
  "& .MuiChartsTooltip-markCell": {
    padding: '3px 3px 0px 3px !important',
    "& .MuiChartsTooltip-mark": {
      border: 'none',
      height: '12px',
      width: '12px'
    }
  },
  '& .MuiChartsTooltip-valueCell': {
    padding: '3px 3px 0px 3px !important',
    "& .MuiTypography-body1": {
      color: 'white',
      fontWeight: '500'
    }
  }
}));

const CustomPopperRoot = (props: PopperProps) => {
  return (
    <ChartsTooltipRoot
      {...props}
      anchorEl={{
        getBoundingClientRect: () => ({
          ...(props.anchorEl as any)?.getBoundingClientRect(),
        }),
      }}
    />
  );
};


interface CustomAnimatedLineProps extends AnimatedLineProps {
  limit?: number;
  sxBefore?: SxProps<Theme>;
  sxAfter?: SxProps<Theme>;
}

const CustomAnimatedLine = (props: CustomAnimatedLineProps) => {
  const { limit, sxBefore, sxAfter, ...other } = props;
  const { top, bottom, height, left, width } = useDrawingArea();
  const scale = useXScale();
  const chartId = useChartId();
  const classNames = props.className as string;

  if (classNames.includes('MuiLineElement-series-forecast')) {
    return <AnimatedLine {...other} />;
  }

  if (limit === undefined) {
    return <AnimatedLine {...other} />;
  }

  const limitPosition = scale(limit); // Convert value to x coordinate.

  if (limitPosition === undefined) {
    return <AnimatedLine {...other} />;
  }

  const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
  const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
  return (
    <>
      {/* Clip to show the line before the limit */}
      <clipPath id={clipIdleft}>
        <rect
          x={left}
          y={0}
          width={limitPosition - left}
          height={top + height + bottom}
        />
      </clipPath>
      {/* Clip to show the line after the limit */}
      <clipPath id={clipIdRight}>
        <rect
          x={limitPosition}
          y={0}
          width={left + width - limitPosition}
          height={top + height + bottom}
        />
      </clipPath>
      <g clipPath={`url(#${clipIdleft})`}>
        <AnimatedLine {...other} sx={sxBefore} />
      </g>
      <g clipPath={`url(#${clipIdRight})`}>
        <AnimatedLine {...other} sx={sxAfter} />
      </g>
    </>
  );
}

interface CustomMarkProps extends MarkElementProps {
  currentMonthIndex: number;
}

const CustomMark = (props: CustomMarkProps) => {
  const { currentMonthIndex, ...restProps } = props;
  const color = props.dataIndex <= props.currentMonthIndex ? '#687077' : '#E3E6E8'
  return (
    <MarkElement
      {...restProps}
      shape='circle'
      sx={{
        fill: color,
        stroke: color,
        strokeWidth: '0 !important'
      }}
    />
  )
}

export default FinancialsLineGraph