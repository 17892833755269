import { useTheme } from "@mui/material";
import { useDrawingArea } from "@mui/x-charts-pro";

const FinancialsSummarySpendTotalGradient = () => {
  const theme = useTheme();
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  return (
    <>
      <defs>
        <linearGradient id="blue_gradient" x1="300" y1="-4" x2="300" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.palette.blue[600]} stopOpacity="0.4" />
          <stop offset="1" stopColor={theme.palette.blue[600]} stopOpacity="0" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="grey_gradient" x1="300" y1="-4" x2="300" y2={`${svgHeight}px`} gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.palette.grey[600]} stopOpacity="0.4" />
          <stop offset="1" stopColor={theme.palette.grey[600]} stopOpacity="0" />
        </linearGradient>
      </defs>
    </>
  )
}
export default FinancialsSummarySpendTotalGradient;