import { CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { BudgetMakePaymentRoutesDefinition, useBudgetMakePaymentRoutesParams } from "../BudgetMakePaymentRoutes";
import { PaymentConfirm } from "modules/project/_components";
import { FinancialRoutesDefinition } from "modules/project/financials/FinancialsRoutes";

const BudgetMakePaymentAllocate = () => {
  const routing = CoreRouting.useRouting();

  //  params
  const { projectId, stageId, taskId, paymentScheduleId } = useBudgetMakePaymentRoutesParams();
  const taskListParams = { projectId, stageId, taskId };
  const quoteListParams = { projectId, stageId, taskId };
  const paymentScheduleParams = { projectId, paymentScheduleId };

  // queries 
  const taskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListParams);
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(quoteListParams);
  const paymentScheduleQuery = CoreState.Financial.getPaymentSchedule.useQuery(paymentScheduleParams);

  // mutations
  const [allocatePaymentScheduleAction, allocatePaymentScheduleQuery] = CoreState.Financial.allocatePaymentSchedule.useMutation();

  // locals
  const isLoading =
    Boolean(!taskListByParentQuery.isUninitialized && taskListByParentQuery.isLoading) ||
    Boolean(!paymentScheduleQuery.isUninitialized && paymentScheduleQuery.isLoading) ||
    Boolean(!quoteListQuery.isUninitialized && quoteListQuery.isLoading);
  const isSaving = Boolean(!allocatePaymentScheduleQuery.isUninitialized && allocatePaymentScheduleQuery.isLoading);
  const isSuccess = Boolean(!allocatePaymentScheduleQuery.isUninitialized && allocatePaymentScheduleQuery.isSuccess);
  const isError = Boolean(!allocatePaymentScheduleQuery.isUninitialized && allocatePaymentScheduleQuery.isError);
  const acceptedQuote = useSelector(s => CoreState.Quote.selectQuoteListByLatestQuoteRequestAcceptedQuote(s, quoteListParams));
  const company = acceptedQuote?.company
  const companyName = company?.name || "";
  const task = useSelector(s => CoreState.Task.selectTaskListByParentCurrentTask(s, taskListParams))
  const paymentSchedule = useSelector(s => CoreState.Financial.selectPaymentSchedule(s, paymentScheduleParams));

  // events
  const handleCancelClick = () => {
    routing.go(BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT, { persistSearch: true })
  }

  const handleSubmitClick = () => {
    allocatePaymentScheduleAction({ projectId, taskId, paymentScheduleId });
  }

  useEffect(() => {
    if (isError) {
      routing.go(`${FinancialRoutesDefinition.BUDGET_TRACKING.path}`, { persistSearch: true });
      return;
    };
    if (!isSuccess) return;
    const params = { type: [CoreState.Financial.PaymentType.PayTo] };
    routing.go(`${BudgetMakePaymentRoutesDefinition.BUDGET_MAKE_PAYMENT_RECEIPT.path}`, { params, persistSearch: true });
  }, [isSuccess, isError]);

  return (
    <PaymentConfirm
      title="Allocate funds"
      paymentType={CoreState.Financial.PaymentType.PayTo}
      paymentSchedule={paymentSchedule}
      task={task}
      recipientName={companyName}
      isLoading={isLoading}
      isSaving={isSaving}
      submitText="Request Authorisation"
      onSubmitClick={() => handleSubmitClick()}
      onCancelClick={() => handleCancelClick()}
    />
  )
}
export default BudgetMakePaymentAllocate;