import { ZoomData } from "@mui/x-charts-pro";
import { useState, useEffect } from "react";

const useZoom = (axisId: string, size: number, length: number, show: number, position: number) => {
  const [zoom, setZoom] = useState<ZoomData[]>([{
    axisId: axisId,
    start: 0,
    end: 100,
  }]);

  useEffect(() => {
    // if the size of the container is >= 400, we show the defined amount. Otherwise we ALWAYS show 4 items
    let noToShow = Boolean(size >= 400) ? show : 4;
    // if the numberToShow is greater than number of items in the array, just show everything
    noToShow = Boolean(noToShow > length) ? length : noToShow;

    // we calculate the start percentage of the zoom
    const zoomAmount = Math.round((noToShow / length) * 100);
    const zoomPosition = Math.round((position / length) * 100);

    // calculate the end position
    const calcEnd = zoomPosition + zoomAmount;
    // if the calculated end is > 100, then we minus 100 from the zoomAmount. Otherwise just use the zoomPosition
    const start = (calcEnd > 100) ? 100 - zoomAmount : zoomPosition;
    // use calculated end and cap at 100
    const end = Math.min(calcEnd, 100);

    setZoom([{ axisId, start, end }]);
  }, [size, length, show])

  return { zoom, setZoom };
}

export default useZoom;